/* eslint-disable jsx-a11y/anchor-is-valid, no-script-url */

import * as React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import standardDinosaurTopFacingLeft from '../../../assets/dinosaur-top-facing-left.png';
import retinaDinosaurTopFacingLeft from '../../../assets/dinosaur-top-facing-left@2x.png';
import standardDinosaurTopFacingLeftWaving from '../../../assets/dinosaur-top-facing-left-waving.png';
import retinaDinosaurTopFacingLeftWaving from '../../../assets/dinosaur-top-facing-left-waving@2x.png';
import standardDinosaurTopFacingRight from '../../../assets/dinosaur-top-facing-right.png';
import retinaDinosaurTopFacingRight from '../../../assets/dinosaur-top-facing-right@2x.png';
import standardDinosaurRightSideWaving from '../../../assets/dinosaur-right-side-waving.png';
import retinaDinosaurRightSideWaving from '../../../assets/dinosaur-right-side-waving@2x.png';
import standardDinosaurRightSidePeeking from '../../../assets/dinosaur-right-side-peeking.png';
import retinaDinosaurRightSidePeeking from '../../../assets/dinosaur-right-side-peeking@2x.png';
import standardDinosaurRightSide from '../../../assets/dinosaur-right-side.png';
import retinaDinosaurRightSide from '../../../assets/dinosaur-right-side@2x.png';

import * as styles from './Modal.css';
import { CrossIcon } from '../../../core/components/Icon';
import Image from '../../../core/components/image/Image';
import * as utils from '../../../core/utils';

class Modal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    hasSkipForNow: PropTypes.bool,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    hasSkipForNow: false,
  };

  static availableImages = [
    {
      name: 'dinosaur-top-facing-left',
      standard: standardDinosaurTopFacingLeft,
      retina: retinaDinosaurTopFacingLeft,
      isMobilePortraitOnly: true,
    },
    {
      name: 'dinosaur-top-facing-left-waving',
      standard: standardDinosaurTopFacingLeftWaving,
      retina: retinaDinosaurTopFacingLeftWaving,
      isMobilePortraitOnly: true,
    },
    {
      name: 'dinosaur-top-facing-right',
      standard: standardDinosaurTopFacingRight,
      retina: retinaDinosaurTopFacingRight,
      isMobilePortraitOnly: true,
    },
    {
      name: 'dinosaur-right-side-waving',
      standard: standardDinosaurRightSideWaving,
      retina: retinaDinosaurRightSideWaving,
      isMobilePortraitOnly: false,
    },
    {
      name: 'dinosaur-right-side',
      standard: standardDinosaurRightSide,
      retina: retinaDinosaurRightSide,
      isMobilePortraitOnly: false,
    },
    {
      name: 'dinosaur-right-side-peeking',
      standard: standardDinosaurRightSidePeeking,
      retina: retinaDinosaurRightSidePeeking,
      isMobilePortraitOnly: false,
    },
  ];

  state = {
    closingTimer: null,
    imageIndex: Math.floor(Math.random() * Modal.getAvailableImages().length),
    isOpen: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.isOpen || prevState.isOpen || prevState.closingTimer) {
      return {
        isOpen: nextProps.isOpen,
      };
    }

    const randomNumber = Math.floor(Math.random() * 1000);
    const nextImageIndex =
      (prevState.imageIndex + randomNumber) % Modal.getAvailableImages().length;

    utils.preventBodyScrolling();

    return {
      imageIndex: nextImageIndex,
      isOpen: nextProps.isOpen,
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleWindowKeydown, true);
  }

  componentWillUnmount() {
    utils.restoreBodyScrolling();
    window.removeEventListener('click', this.handleWindowKeydown);
  }

  static getAvailableImages() {
    const isMobile = utils.isMobile();
    const isPortrait = utils.isPortrait();

    if (!isMobile) {
      return Modal.availableImages;
    }

    return Modal.availableImages.filter((image) => {
      if (isPortrait) {
        return image.isMobilePortraitOnly;
      }

      return !image.isMobilePortraitOnly;
    });
  }

  handleWindowKeydown = ({ key, keyCode, target }) => {
    const isEscapeKey = key === 'Escape' || key === 'Esc' || keyCode === 27;
    const isTargetBody = target.nodeName === 'BODY';

    if (!isEscapeKey || !isTargetBody) {
      return;
    }

    if (!this.props.isOpen) {
      return;
    }

    this.handleClose();
  };

  handleClose(hasSubmitted = false) {
    this.props.onClose(hasSubmitted);
    clearInterval(this.state.closingTimer);
    utils.restoreBodyScrolling();

    const MODAL_CLOSE_DURATION_MS = 1000;
    const closingTimer = setTimeout(() => {
      this.setState(({ closingTimer }) => {
        clearInterval(closingTimer);
        return {
          closingTimer: null,
        };
      });
    }, MODAL_CLOSE_DURATION_MS);

    this.setState({ closingTimer });
  }

  render() {
    const { children, isOpen } = this.props;
    const { closingTimer, imageIndex } = this.state;
    const isClosing = Boolean(closingTimer);
    const modalBackdropStyles = cx(styles.modalBackdrop, {
      [styles.modalBackdropOpen]: isOpen || isClosing,
      [styles.modalBackdropClosing]: isClosing,
    });
    const dinosaurImage = Modal.getAvailableImages()[imageIndex] || Modal.availableImages[0];
    const dinosaurImageStyles = cx(
      styles.modalDinosaurImage,
      styles[`modal__dinosaur-image--${dinosaurImage.name}`],
    );

    return (
      <div className={modalBackdropStyles}>
        <div className={styles.modal}>
          <span className={styles.modalClose} onClick={this.handleClose.bind(this)}>
            <CrossIcon />
          </span>
          <div className={dinosaurImageStyles}>
            <Image {...dinosaurImage} alt="Dinosaur" />
          </div>
          <div className={styles.modalContents}>
            {children}
            <a
              onClick={this.handleClose.bind(this)}
              href="javascript:void(0);"
              className={styles.skipForNow}
            >
              Skip For Now
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
