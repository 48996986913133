/* eslint-disable jsx-a11y/anchor-is-valid, no-script-url */

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import cx from 'classnames';

import standardLogo from '../../../assets/logo.png';
import retinaLogo from '../../../assets/logo@2x.png';

import { IconButton, YellowButton } from '../../../core/components/button/Button';
import { MegaphoneIcon, HamburgerIcon, CrossIcon, ShareIcon } from '../../../core/components/Icon';

import * as commonStyles from '../../../index.css';
import * as styles from './NavBar.css';

import Configuration from '../../../core/shared/configuration';
import ShareModal from '../modal/ShareModal';

class NavBar extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    onGetNotifiedClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    title: '',
    subtitle: '',
  };

  state = {
    isMobileDropdownOpen: false,
    isShareModalOpen: false,
  };

  handleGetNotifiedClick(e) {
    e && e.preventDefault();

    this.setState({ isMobileDropdownOpen: false });
    this.props.onGetNotifiedClick();
  }

  handleHamburgerClick() {
    this.setState(({ isMobileDropdownOpen }) => ({
      isMobileDropdownOpen: !isMobileDropdownOpen,
    }));
  }

  handleShareModalChange() {
    this.setState(({ isShareModalOpen }) => ({
      isShareModalOpen: !isShareModalOpen,
      isMobileDropdownOpen: false,
    }));
  }

  render() {
    const { title, subtitle } = this.props;
    const { isMobileDropdownOpen, isShareModalOpen } = this.state;
    const mobileDropdownStyles = cx(commonStyles.portableDown, styles.navBarMobileDropdown, {
      [styles.navBarMobileDropdownOpen]: isMobileDropdownOpen,
    });
    const { REQUEST_SHOUTOUT_FORM_URL } = new Configuration();

    return (
      <>
        <nav className={styles.navBar}>
          <div className={styles.navBarTop}>
            <div className={styles.logo}>
              <Link to="/">
                <img
                  src={retinaLogo}
                  srcSet={`${standardLogo}, ${retinaLogo} 2x`}
                  alt="The Birthday Show"
                />
              </Link>
            </div>
            <ul className={styles.navBarItems}>
              <li className={commonStyles.desktopUp}>
                <NavLink activeClassName={styles.active} exact to="/">
                  Today&apos;s Show
                </NavLink>
              </li>
              <li className={commonStyles.desktopUp}>
                <NavLink activeClassName={styles.active} exact to="/shows">
                  Past Shows
                </NavLink>
              </li>
              <li className={commonStyles.desktopUp}>
                <NavLink activeClassName={styles.active} exact to="/about">
                  About
                </NavLink>
              </li>
              <li className={`${styles.hasBtn} ${commonStyles.desktopUp}`}>
                <YellowButton
                  label="Be in the show!"
                  icon={<MegaphoneIcon />}
                  href={REQUEST_SHOUTOUT_FORM_URL}
                />
              </li>
              <li
                className={`${styles.hasBtn} ${commonStyles.desktopUp}`}
                ref={this.shareButtonRef}
              >
                <YellowButton
                  label="Share"
                  icon={<ShareIcon />}
                  onClick={() => this.handleShareModalChange()}
                />
              </li>
              <li className={`${commonStyles.portableDown} ${styles.hasBtn}`}>
                <IconButton icon={<HamburgerIcon />} onClick={() => this.handleHamburgerClick()} />
              </li>
            </ul>
          </div>
          <div className={styles.navBarBottom}>
            {title && <h1>{title}</h1>}
            {subtitle && <p className={commonStyles.container}>{subtitle}</p>}
          </div>
          <div className={mobileDropdownStyles}>
            <div className={styles.navBarMobileDropdownMenu}>
              <div className={styles.navBarMobileDropdownMenuTop}>
                <ul className={styles.navBarItems}>
                  <li>
                    <NavLink activeClassName={styles.active} exact to="/">
                      Today&apos;s Show
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName={styles.active} exact to="/shows">
                      Past Shows
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName={styles.active} exact to="/about">
                      About
                    </NavLink>
                  </li>
                </ul>
                <IconButton icon={<CrossIcon />} onClick={() => this.handleHamburgerClick()} />
              </div>
              <div className={styles.navBarMobileDropdownMenuBottom}>
                <ul className={styles.navBarItems}>
                  <li>
                    <a target="_blank noreferrer noopener" href={REQUEST_SHOUTOUT_FORM_URL}>
                      Be in the show!
                    </a>
                  </li>
                  <li>
                    <a onClick={this.handleGetNotifiedClick.bind(this)} href="javascript:void(0);">
                      Sign Up
                    </a>
                  </li>
                  <li>
                    <a onClick={this.handleShareModalChange.bind(this)} href="javascript:void(0);">
                      Share
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <ShareModal isOpen={isShareModalOpen} onClose={() => this.handleShareModalChange()} />
      </>
    );
  }
}

export default NavBar;
