import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as styles from './Modal.css';

export class ModalTabs extends React.PureComponent {
  static propTypes = {
    tabLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
    children: PropTypes.node.isRequired,
  };

  state = {
    activeTabIndex: 0,
  };

  handleChangeActiveTabIndex(tabIndex) {
    this.setState({ activeTabIndex: tabIndex });

    // TODO: Remove this when we enable "Throw a Party!"
    if (tabIndex === 1) {
      setTimeout(() => {
        if (this.state.activeTabIndex === 1) {
          alert('"Throw a Party" is Coming Soon!');
          this.setState({ activeTabIndex: 0 });
        }
      }, 350);
    }
  }

  render() {
    const { tabLabels, children } = this.props;
    const { activeTabIndex } = this.state;
    const listElements = tabLabels.map((tabLabel, i) => {
      const listStyles = cx({ [styles.modalTabsHeaderListItemActive]: i === activeTabIndex });

      return (
        <li key={i} className={listStyles} onClick={() => this.handleChangeActiveTabIndex(i)}>
          {tabLabel}
        </li>
      );
    });
    const modalTabsBodyStyles = cx(styles.modalTabsBody, {
      [styles.modalTabsBodyShifted]: activeTabIndex !== 0,
    });

    return (
      <div className={styles.modalTabs}>
        <div className={styles.modalTabsHeader}>
          <ul>
            {listElements}
            <div className={styles.modalTabsHeaderSlider}>
              <hr />
            </div>
          </ul>
        </div>
        <div className={modalTabsBodyStyles}>{children}</div>
      </div>
    );
  }
}

export const ModalTab = ({ children }) => <div className={styles.modalTab}>{children}</div>;
ModalTab.propTypes = {
  children: PropTypes.node.isRequired,
};
