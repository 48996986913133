// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Image_image___22i_IrEbAv {\n  /* Significantly speeds up scroll-based animations on safari - https://stackoverflow.com/a/24157294 */\n  -webkit-backface-visibility: hidden;\n          backface-visibility: hidden;\n  perspective: 1000;\n  transform: translate3d(0, 0, 0); /* \"Hack\" to force Safari Hardware Acceleration */\n  max-width: 100%;\n  max-height: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"image": "Image_image___22i_IrEbAv"
};
module.exports = exports;
