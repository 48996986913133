import Configuration from './configuration';

class RequestService {
  constructor() {
    this.config = new Configuration();
  }

  async createItem(newitem, url) {
    return fetch(url, {
      method: 'post',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: newitem,
    })
      .then((response) => {
        if (!response.ok) {
          this.handleResponseError(response);
        }
        return response.json();
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  handleResponseError(response) {
    throw new Error(`HTTP error, status = ${response.status}`);
  }

  handleError(error) {
    console.log(error.message);
  }
}
export default RequestService;
