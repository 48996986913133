import * as React from 'react';
import PropTypes from 'prop-types';

import NavBar from '../navbar/NavBar';
import Notes from '../notes/Notes';
import SignUpModal from '../modal/SignUpModal';

class PageLayout extends React.PureComponent {
  static defaultProps = {
    title: '',
    subtitle: '',
  };

  state = {
    isLive: false,
    isSignUpModalOpen: false,
  };

  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    isSignupFormHidden: PropTypes.bool,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    isSignupFormHidden: false,
  };

  notesRef = React.createRef();

  handleSignUpModalChange() {
    this.setState(({ isSignUpModalOpen }) => ({ isSignUpModalOpen: !isSignUpModalOpen }));
  }

  handleIsLiveChange(isLive) {
    this.setState({ isLive });
  }

  render() {
    const { title, subtitle, isSignupFormHidden, children } = this.props;
    const { isLive, isSignUpModalOpen } = this.state;

    return (
      <div>
        <NavBar
          title={title}
          subtitle={subtitle}
          onGetNotifiedClick={() => this.handleSignUpModalChange()}
        />
        {children}
        {!isSignupFormHidden && <Notes isLive={isLive} ref={this.notesRef} />}
        <SignUpModal isOpen={isSignUpModalOpen} onClose={() => this.handleSignUpModalChange()} />
      </div>
    );
  }
}

export default PageLayout;
