import {
  MAILCHIMP_POST_URL,
  MAILCHIMP_USER_ID,
  MAILCHIMP_AUDIENCE_ID,
  REGISTER_LOCALSTORAGE_KEY,
} from './constants';
import RestService from '../../../core/shared/requestService';

export async function signUp(fullName, email) {
  const formData = new FormData();
  const splitName = fullName.split(' ');
  const firstName = splitName.slice(0, -1).join(' ') || fullName;
  const lastName = splitName.length > 1 ? splitName.slice(-1).join(' ') : '';

  formData.append('MERGE0', email);
  formData.append('MERGE1', firstName);
  formData.append('MERGE2', lastName);
  formData.append('u', MAILCHIMP_USER_ID);
  formData.append('id', MAILCHIMP_AUDIENCE_ID);

  const restService = new RestService();
  await restService.createItem(formData, MAILCHIMP_POST_URL);

  localStorage.setItem(REGISTER_LOCALSTORAGE_KEY, true);
}

export function hasSignedUp() {
  return Boolean(localStorage.getItem(REGISTER_LOCALSTORAGE_KEY));
}
