// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Alert_alert-container___2Cx3zoEABP {\n  display: flex;\n  justify-content: right;\n}\n\n.Alert_alert___abQSBW4ub_ {\n  background-color: rgba(67, 194, 204, 0.2);\n  border-radius: 7px;\n  display: flex;\n  padding: 1em 2em;\n  align-items: center;\n}\n\n.Alert_alert___abQSBW4ub_ p,\n  .Alert_alert___abQSBW4ub_ span {\n    color: rgba(67, 194, 204, 1);\n    font-weight: 500;\n    font-size: 24px;\n    line-height: 23px;\n  }\n\n.Alert_alert___abQSBW4ub_ p {\n    flex-grow: 1;\n    margin: 0;\n    margin-right: 1em;\n  }\n\n.Alert_alert___abQSBW4ub_ span {\n    height: 27px;\n    width: 22px;\n  }\n\n@media (max-width: 576px) {\n  .Alert_alert-container___2Cx3zoEABP {\n    justify-content: center;\n  }\n\n  .Alert_alert___abQSBW4ub_ {\n    padding-left: 1.25em;\n    padding-right: 1.25em;\n  }\n\n    .Alert_alert___abQSBW4ub_ p {\n      font-size: 21px;\n      margin-right: 0.5em;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"alert-container": "Alert_alert-container___2Cx3zoEABP",
	"alertContainer": "Alert_alert-container___2Cx3zoEABP",
	"alert": "Alert_alert___abQSBW4ub_"
};
module.exports = exports;
