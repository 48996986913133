/* eslint-disable jsx-a11y/anchor-is-valid, no-script-url */

import * as React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import standardDinosaurLeftSideImage from '../../../assets/dinosaur-left-side.png';
import retinaDinosaurLeftSideImage from '../../../assets/dinosaur-left-side@2x.png';
import standardPartyHatButtonImage from '../../../assets/party-hat-button.png';
import retinaPartyHatButtonImage from '../../../assets/party-hat-button@2x.png';
import Configuration from '../../../core/shared/configuration';

import * as commonStyles from '../../../index.css';
import * as styles from './Notes.css';

import { LightBlueButton } from '../../../core/components/button/Button';
import Input from '../../../core/components/input/Input';
import { INPUT_TYPES } from '../../../core/components/input/constants';
import { ArrowRightIcon, CheckIcon } from '../../../core/components/Icon';
import Alert from '../alert/Alert';
import * as mailchimpService from '../../services/mailchimp/mailchimpService';

export default class Notes extends React.Component {
  static propTypes = {
    isLive: PropTypes.bool,
  };

  static defaultProps = {
    isLive: false,
  };

  constructor(props) {
    super(props);

    this.configuration = new Configuration();
    this.firstNameRef = React.createRef();

    this.state = {
      isHidden: mailchimpService.hasSignedUp(),
      hasExpandedMobileForm: false,
      profile: {
        fullName: {
          value: '',
          isValid: false,
        },
        email: {
          value: '',
          isValid: false,
        },
      },
      hasChanged: false,
      hasSubmitted: false,
    };
  }

  handleLearnMoreClick(e) {
    e && e.preventDefault();

    const focusInput = () => {
      this.firstNameRef.current.focusInput();
    };
    const isFooterInViewApprox =
      document.body.scrollHeight - window.scrollY - window.innerHeight <= 100;

    // If the user is already pretty much at the bottom, just focus the input.
    // Otherwise, smoothly scroll down.
    if (isFooterInViewApprox) {
      focusInput();
    } else {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });

      setTimeout(focusInput, 300);
    }
  }

  handleProfileChange(prop, value, isValid) {
    this.setState(({ profile }) => ({
      hasChanged: true,
      profile: {
        ...profile,
        [prop]: {
          value,
          isValid,
        },
      },
    }));
  }

  async handleSubmit(e) {
    e && e.preventDefault();

    const canSubmit = this.isValid();
    if (!canSubmit) {
      return;
    }

    const { profile } = this.state;
    await mailchimpService.signUp(profile.fullName.value, profile.email.value);

    this.setState({ hasSubmitted: true });

    setTimeout(() => this.setState({ isHidden: true }), 5000);
  }

  handleMobileExpandClick(e) {
    e && e.preventDefault();
    this.setState({ hasExpandedMobileForm: true });
    setTimeout(() => this.handleLearnMoreClick(), 500);
  }

  isValid() {
    const profileValues = Object.values(this.state.profile);
    const isValid = profileValues.every((field) => field.isValid);

    return isValid;
  }

  render() {
    const { isLive } = this.props;
    const { profile, hasSubmitted, hasChanged, isHidden, hasExpandedMobileForm } = this.state;
    const canSubmit = this.isValid();
    const notesStyles = cx(styles.notes, commonStyles.row, {
      [styles.notesChanged]: hasChanged,
      [styles.notesSubmitted]: hasSubmitted,
      [styles.notesHidden]: isHidden,
      [styles.notesExpandedMobileForm]: hasExpandedMobileForm,
    });
    const extraDescriptionText = !isLive
      ? ', a new mobile, interactive video platform for kids’ entertainment-based learning.'
      : '.';

    return (
      <div className={notesStyles}>
        <div className={styles.notesTop}>
          <div className={styles.notesTopDescription}>
            <img
              src={retinaDinosaurLeftSideImage}
              srcSet={`${standardDinosaurLeftSideImage}, ${retinaDinosaurLeftSideImage} 2x`}
              alt="Dinosaur"
            />
            <p>
              <span>The Birthday Show</span> is brought to you by <span>Hellosaurus</span>
              {extraDescriptionText}
              <br />
              Coming soon to iOS and Android.
            </p>
            <a
              className={commonStyles.portableUp}
              onClick={this.handleLearnMoreClick.bind(this)}
              href="javascript:void(0);"
            >
              Signup to learn more
            </a>
            <a
              className={commonStyles.mobileDown}
              onClick={this.handleMobileExpandClick.bind(this)}
              href="javascript:void(0);"
            >
              Signup to learn more
            </a>
          </div>
        </div>
        <div className={styles.notesBottom}>
          <div className={styles.notesBottomBackground} />
          <div className={`${styles.notesBottomPointer} ${commonStyles.desktopUp}`}>
            <img
              src={retinaPartyHatButtonImage}
              srcSet={`${standardPartyHatButtonImage}, ${retinaPartyHatButtonImage} 2x`}
              alt="Signup is for grownups :)"
            />
          </div>
          <p className={styles.notesBottomDescription}>
            Having fun? Drop your info below and we’ll notify you of new episodes of The Birthday
            Show as well as releases of future interactive shows and other Hellosaurus updates.
          </p>
          <p className={`${styles.notesBottomSignupIsForGrownups} ${commonStyles.mobileDown}`}>
            Signup is for grownups :)
          </p>
          <div className={styles.notesBottomFormContainer}>
            <form onSubmit={this.handleSubmit.bind(this)}>
              <Input
                ref={this.firstNameRef}
                placeholder="Full Name"
                value={profile.fullName.value}
                onChange={(value, isValid) => this.handleProfileChange('fullName', value, isValid)}
              />
              <Input
                placeholder="Email"
                value={profile.email.value}
                onChange={(value, isValid) => this.handleProfileChange('email', value, isValid)}
                type={INPUT_TYPES.EMAIL}
              />
              <LightBlueButton
                icon={<ArrowRightIcon />}
                isDisabled={!canSubmit}
                onClick={(e) => this.handleSubmit(e)}
              />
              {/* Allows submission of form through pressing enter */}
              <button aria-label="Submit" type="submit" hidden />
            </form>
            <div className={styles.notesBottomFormCompletedAlert}>
              <Alert label="Welcome to the party!" icon={<CheckIcon />} />
            </div>
          </div>
        </div>
        <div className={styles.notesExpandMobileForm}>
          <LightBlueButton
            onClick={(e) => this.handleMobileExpandClick(e)}
            label="Signup to get updates"
          />
        </div>
      </div>
    );
  }
}
