import * as React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import DatePicker from 'react-datepicker';

import { INPUT_TYPES, INPUT_COLORS, INPUT_DEBOUNCE_MS } from './constants';
import * as styles from './Input.css';
import * as utils from './utils';

class Input extends React.PureComponent {
  static propTypes = {
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(Object.values(INPUT_TYPES)),
    color: PropTypes.oneOf(Object.values(INPUT_COLORS)),
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onDebounced: PropTypes.func,
    label: PropTypes.string,
  };

  static defaultProps = {
    type: INPUT_TYPES.TEXT,
    placeholder: '',
    color: null,
    label: '',
    onBlur: () => {},
    onDebounced: null,
  };

  state = {
    hasChanged: false,
    parentFocusCount: 0,
    debounceTimer: null,
  };

  inputRef = React.createRef();

  focusInput = () => {
    this.inputRef.current && this.inputRef.current.focus();
    this.setState(({ parentFocusCount }) => ({ parentFocusCount: parentFocusCount + 1 }));
  };

  handleChange(value) {
    const { onChange, type, onDebounced } = this.props;
    const isValid = utils.isInputValid(type, value);

    if (onDebounced && isValid) {
      const newDebounceTimer = setTimeout(this.props.onDebounced, INPUT_DEBOUNCE_MS);
      this.setState({ debounceTimer: newDebounceTimer });
    }

    onChange && onChange(value, isValid);
    this.setState({ hasChanged: true });
  }

  handleKeyUp(e) {
    e.stopPropagation();
  }

  handleKeyDown() {
    clearTimeout(this.state.debounceTimer);
  }

  handleBlur() {
    clearTimeout(this.state.debounceTimer);
    this.props.onBlur();
  }

  getInputElement() {
    const { placeholder, value, type } = this.props;
    const inputElement =
      type !== INPUT_TYPES.DATE ? (
        <input
          placeholder={placeholder}
          value={value}
          onBlur={() => this.handleBlur()}
          onChange={(e) => this.handleChange(e.target.value)}
          onKeyUp={this.handleKeyUp}
          onKeyDown={() => this.handleKeyDown()}
          ref={this.inputRef}
          type={type}
        />
      ) : (
        <DatePicker
          selected={value}
          onChange={(date) => this.handleChange(date)}
          showTimeSelect
          dateFormat="Pp 'NYC'"
        />
      );

    return inputElement;
  }

  render() {
    const { value, type, color, label } = this.props;
    const { hasChanged, parentFocusCount } = this.state;
    const inputStyles = cx(styles.inputContainer, color && styles[`input-container--${color}`], {
      [styles.inputContainerError]: hasChanged && !utils.isInputValid(type, value),
      [styles.inputContainerHighlightFocusLeft]: parentFocusCount > 0 && parentFocusCount % 2 === 0,
      [styles.inputContainerHighlightFocusRight]:
        parentFocusCount > 0 && parentFocusCount % 2 === 1,
    });
    const inputElement = this.getInputElement();

    return (
      <>
        {label && <label>{label}</label>}
        <div className={inputStyles}>{inputElement}</div>
      </>
    );
  }
}

export const PurpleInput = (props) => <Input {...props} color={INPUT_COLORS.PURPLE} />;

export default Input;
