import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import loadable from '@loadable/component';
import 'babel-polyfill';

import './index.css';

import { Router, Route, Switch } from 'react-router';
import { history } from './core/utils';
import ScrollToTop from './core/components/ScrollToTop';
import NotFoundPage from './core/pages/NotFoundPage';

moment.tz.setDefault('America/New_York');

const CmsApplication = loadable(() => import('./cms/index.jsx'));
const TheBirthdayShowApplication = loadable(() => import('./thebirthdayshow/index.jsx'));

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <Route component={ScrollToTop} />
      <Switch>
        <Route path="/cms" component={CmsApplication} />
        <Route path="/" component={TheBirthdayShowApplication} />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// For navbar/modals https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open (See also: utils.js)
window.addEventListener('scroll', () => {
  document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
});
