import { EMAIL_REGEX, INPUT_TYPES } from './constants';

function isPresent(value) {
  return Boolean(value);
}

function isStringPresent(string) {
  return isPresent(string) && string.length <= 70;
}

function isEmailValid(string) {
  return isStringPresent(string) && string.match(EMAIL_REGEX);
}

function isPasswordValid(password) {
  return isStringPresent(password) && password.length >= 6;
}

function isNumberValid(value) {
  if (value === '') {
    return false;
  }

  const valueNumber = Number(value);

  return !isNaN(valueNumber);
}

// eslint-disable-next-line import/prefer-default-export
export function isInputValid(type, value) {
  switch (type) {
    case INPUT_TYPES.EMAIL:
      return isEmailValid(value);
    case INPUT_TYPES.TEXT:
      return isStringPresent(value);
    case INPUT_TYPES.DATE:
      return isPresent(value);
    case INPUT_TYPES.PASSWORD:
      return isPasswordValid(value);
    case INPUT_TYPES.NUMBER:
      return isNumberValid(value);
    default:
      throw Error(`The input type=${type} is not supported!`);
  }
}
