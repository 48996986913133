import * as React from 'react';
import PropTypes from 'prop-types';

import Modal from './Modal';
import {
  FacebookShareButton,
  TwitterShareButton,
  PurpleButton,
} from '../../../core/components/button/Button';
import { PurpleInput } from '../../../core/components/input/Input';
import Configuration from '../../../core/shared/configuration';
import ModalHeader from './ModalHeader';
import { ModalTabs, ModalTab } from './ModalTabs';

const ShareModal = ({ isOpen, onClose }) => {
  const {
    PRODUCTION_URL,
    REQUEST_SHOUTOUT_FORM_URL,
    SHARE_A_PRESENT_FORM_URL,
  } = new Configuration();
  const shareText = `A birthday party all the time?! Check out The Birthday Show by Hellosaurus at https://${PRODUCTION_URL}.`;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader title="Share The Birthday Show!" />
      <ModalTabs tabLabels={['Social', 'Throw a Party!']}>
        <ModalTab>
          <textarea defaultValue={shareText} />
          <FacebookShareButton />
          <TwitterShareButton text={shareText} />
        </ModalTab>
        <ModalTab>
          <p>
            Invite your friends to celebrate with you on The Birthday Show! And don’t forget to{' '}
            <a href={REQUEST_SHOUTOUT_FORM_URL}>Request a Shoutout</a> or{' '}
            <a href={SHARE_A_PRESENT_FORM_URL}>Share a Present</a>.
          </p>
          <form>
            <PurpleInput label="Your name" value="" onChange={() => {}} />
            <PurpleInput label="Select date for invite" value="" onChange={() => {}} />
            <PurpleInput label="Email(s)" value="" onChange={() => {}} />
            <label>Optional message</label>
            <textarea defaultValue="" />
            <PurpleButton label="Send Invitation" />
          </form>
        </ModalTab>
      </ModalTabs>
    </Modal>
  );
};

ShareModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ShareModal;
