import { createBrowserHistory } from 'history';
import moment from 'moment-timezone';
import Configuration from './shared/configuration';
import { COCOS_WIDTH, COCOS_HEIGHT } from './constants';

export function padNumber(number, amount = 2) {
  return String(number).padStart(amount, '0');
}

export const history = createBrowserHistory();

export const MONTHS = {
  JANUARY: 1,
  FEBURARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
};

export async function fetchUrl(url) {
  const baseUrl = `${process.env.API_ADDRESS}${url}`;
  const response = await fetch(baseUrl);
  const json = await response.json();

  return json;
}

export function getHoursMinutesSeconds(seconds) {
  let secondsLeft = seconds;

  if (secondsLeft <= 0) {
    return { hours: 0, minutes: 0, seconds: 0 };
  }

  const MAX_HOURS = 60 * 60 * 99;
  const MAX_MINUTES_SECS = 60 * 59;
  const MAX_SECS = 59;
  const MAX_DISPLAY_SECS = MAX_HOURS + MAX_MINUTES_SECS + MAX_SECS;

  // Display can only show a max of 99:99:99
  if (secondsLeft > MAX_DISPLAY_SECS) {
    return { hours: 99, minutes: 59, seconds: 59 };
  }

  // calculate (and subtract) whole hours
  const hoursPlace = Math.floor(secondsLeft / 3600);
  secondsLeft -= hoursPlace * 3600;

  // calculate (and subtract) whole minutes
  const minutesPlace = Math.floor(secondsLeft / 60) % 60;
  secondsLeft -= minutesPlace * 60;

  // what's left is seconds
  const secondsPlace = secondsLeft % 60;

  return { hours: hoursPlace, minutes: minutesPlace, seconds: secondsPlace };
}

export function getFilterOptions(filteredMonth) {
  const filterOptions = [
    { label: 'Show All', value: null },
    { label: 'January', value: MONTHS.JANUARY },
    { label: 'Feburary', value: MONTHS.FEBURARY },
    { label: 'March', value: MONTHS.MARCH },
    { label: 'April', value: MONTHS.APRIL },
    { label: 'May', value: MONTHS.MAY },
    { label: 'June', value: MONTHS.JUNE },
    { label: 'July', value: MONTHS.JULY },
    { label: 'August', value: MONTHS.AUGUST },
    { label: 'September', value: MONTHS.SEPTEMBER },
    { label: 'October', value: MONTHS.OCTOBER },
    { label: 'November', value: MONTHS.NOVEMBER },
    { label: 'December', value: MONTHS.DECEMBER },
  ];
  filterOptions[filteredMonth || 0].isSelected = true;

  return filterOptions;
}

function easeInOutQuadAnimationCurve(t) {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

export function easeOutQuad(t) {
  return 1 + --t * t * t; // eslint-disable-line
}

export function easeInQuad(t) {
  return t * t * t;
}

function getProgressFromTo(from, to, animationDecimal, animationCurveFn) {
  return from + (to - from) * animationCurveFn(animationDecimal);
}

function getCssNumberAndUnit(styleValue) {
  const [_, numberStr, unit] = styleValue.match(/^([+-]?(?:\d+|\d*\.\d+))([a-z]*|%)$/);
  const number = Number(numberStr);

  return [number, unit];
}

// Given a percentage of the animation, calculates the style's value in the animation.
// e.g. From 2px, To 4px, animationDecimal 0.5 would return 3px
export function getStyleProgressFromTo(
  fromStyleValue,
  toStyleValue,
  animationDecimal,
  animationCurveFn = easeInOutQuadAnimationCurve,
) {
  const [fromStyleNumber, fromStyleUnit] = getCssNumberAndUnit(fromStyleValue);
  const [toStyleNumber] = getCssNumberAndUnit(toStyleValue);
  const currentStyleNumber = getProgressFromTo(
    fromStyleNumber,
    toStyleNumber,
    animationDecimal,
    animationCurveFn,
  );
  const currentStyleValue = `${currentStyleNumber}${fromStyleUnit}`;

  return currentStyleValue;
}

export function getScrollPercentDecimal() {
  const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
  const bodyScrollTop = document.body.scrollTop;

  return (scrollTop + bodyScrollTop) / (scrollHeight - clientHeight);
}

// For navbar/modals https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open (See also: index.jsx)
export function preventBodyScrolling() {
  const { documentElement } = document;
  const scrollY = documentElement.style.getPropertyValue('--scroll-y');
  const { body } = document;

  body.style.position = 'fixed';
  body.style.top = `-${scrollY}`;
}

export function restoreBodyScrolling() {
  const { body } = document;
  const scrollY = body.style.top;

  body.style.position = '';
  body.style.top = '';

  window.scrollTo(0, Number(scrollY || '0') * -1);
}

export function isMobile() {
  return Math.min(window.innerWidth, window.innerHeight) <= 640;
}

export function isPortrait() {
  return window.innerWidth < window.innerHeight;
}

export function canRotate() {
  return 'onorientationchange' in window;
}

export function destroyCocosInstance() {
  try {
    document
      .querySelectorAll("script:not([src='/bundle.js']")
      .forEach((element) => element.remove());
    window.cc = null;
    window.g_ressources = null;
  } catch (e) {
    console.error('Could not destroy the cocos instance!', e);
  }
}

export function setupOGTags() {
  const { PRODUCTION_URL } = new Configuration();
  const baseUrl = `https://${PRODUCTION_URL}`;
  const thumbnailUrl = PRODUCTION_URL.includes('hellosaurus.com')
    ? `${baseUrl}/thumbnail-hs.png`
    : `${baseUrl}/thumbnail-tbd.png`;

  document.querySelector('meta[property="og:image"]').setAttribute('content', thumbnailUrl);
  document.querySelector('meta[property="og:url"]').setAttribute('content', baseUrl);
}

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function getNextMiddayDate() {
  const momentNow = moment();
  if (momentNow.hours() < 12) {
    return momentNow.hour(12).minute(0).second(0).toDate();
  }

  return momentNow.add(1, 'day').hour(12).minute(0).second(0).toDate();
}

export function makeFullscreen(querySelector) {
  document.getElementById('root').style.display = 'none';

  const videoPlayerWithMiniGame = document.querySelector(querySelector);
  const originalParentElement = videoPlayerWithMiniGame.parentElement;
  const body = document.querySelector('body');

  body.appendChild(videoPlayerWithMiniGame);
  body.style.overflow = 'hidden';

  return originalParentElement;
}

export function removeFullscreen(querySelector, originalParentElement) {
  document.getElementById('root').style.display = 'block';
  const body = document.querySelector('body');
  const videoPlayerWithMiniGame = document.querySelector(querySelector);
  originalParentElement.appendChild(videoPlayerWithMiniGame);
  body.style.overflow = 'scroll';
}

const AspectRatio = {
  VIDEO: 16 / 9,
  GAME: 8 / 9,
};

export function setupMinigameStyles(
  deviceWidth = window.innerWidth,
  deviceHeight = window.innerHeight,
) {
  let miniGameWidth = '0';
  let miniGameHeight = '0';
  let miniGameMarginTop = '0';
  let miniGameWidthNumber = 0;
  let miniGameHeightNumber = 0;
  let marginTopNumber = 0;
  if (deviceWidth / AspectRatio.VIDEO <= deviceHeight) {
    if (deviceWidth / 2 >= deviceHeight) {
      miniGameWidthNumber = deviceHeight * AspectRatio.GAME;
      miniGameWidth = `${miniGameWidthNumber}px`;
      miniGameHeight = '100%';
    } else {
      miniGameWidth = '50%';
      miniGameHeightNumber = deviceWidth / AspectRatio.VIDEO;
      miniGameHeight = `${miniGameHeightNumber}px`;
      marginTopNumber = (deviceHeight - miniGameHeightNumber) / 2;
      miniGameMarginTop = `${marginTopNumber}px`;
    }
  } else {
    const miniGameWidthNumber = deviceHeight * AspectRatio.GAME;
    miniGameWidth = `${miniGameWidthNumber}px`;
    miniGameHeight = '100%';
  }
  const minigameContainerStyles = document.getElementById('mini-game-container').style;

  minigameContainerStyles.width = miniGameWidth;
  minigameContainerStyles.height = miniGameHeight;
  minigameContainerStyles.pointerEvents = 'auto';
  minigameContainerStyles.display = 'block';
  minigameContainerStyles.top = miniGameMarginTop;
}

export function removeMinigameStyles() {
  const minigameContainerStyles = document.getElementById('mini-game-container').style;

  minigameContainerStyles.display = 'none';
  minigameContainerStyles.pointerEvents = 'none';
  minigameContainerStyles.left = '100%';
}

export function getSecondsFromTimestamp(timestampStr) {
  if (!timestampStr) {
    return NaN;
  }

  const [minutes, seconds] = timestampStr.split(':').map(Number);
  return minutes * 60 + seconds;
}

export function getTimestampFromSeconds(seconds) {
  const minutesPlace = padNumber(Math.floor(seconds / 60));
  const secondsPlace = padNumber(seconds % 60);

  return `${minutesPlace}:${secondsPlace}`;
}

// Cocos has a fixed internal width/height, and it's coordinate system has a origin at the center.
// Map from the client's width/height with our origin in the top left.
// Bottom Left: (-320, -360), Top Right: (320, 360)
export function toCocosCoordinates(x, y, clientWidth, clientHeight) {
  const mappedWidthX = (x / clientWidth) * COCOS_WIDTH;
  const mappedHeightY = (y / clientHeight) * COCOS_HEIGHT;

  const originZeroX = mappedWidthX - COCOS_WIDTH / 2;
  const originZeroY = mappedHeightY - COCOS_HEIGHT / 2;

  const roundedX = Math.round(originZeroX);
  const roundedY = Math.round(originZeroY);

  return {
    x: roundedX,
    y: roundedY * -1,
  };
}

export function toCanvasCoordinates(x, y, clientWidth, clientHeight, canvasWidth, canvasHeight) {
  const webGlWidth = canvasWidth;
  const webGlHeight = canvasHeight;
  const mappedWidthX = (x / clientWidth) * webGlWidth;
  const mappedHeightY = webGlHeight - (y / clientHeight) * webGlHeight;

  return {
    x: mappedWidthX,
    y: mappedHeightY,
  };
}

export function getCanvasClickDetails(
  e,
  { startCocosX = null, startCocosY = null, startWebGLX = null, startWebGLY = null },
) {
  const editShowVideoContainer = document.querySelector('.mini-game-canvas');
  const editShowVideoContainerParent = editShowVideoContainer.parentElement;
  const canvas = editShowVideoContainer.querySelector('canvas');

  // The parent has relative positioning, so use it for the top
  const { offsetTop } = editShowVideoContainerParent.offsetParent;
  const { offsetLeft, offsetWidth, offsetHeight } = editShowVideoContainer;
  const { pageX, pageY } = e;

  const x = pageX - offsetLeft;
  const y = pageY - offsetTop;

  function getWebGLDetails() {
    const { x: endWebGLX, y: endWebGLY } = toCanvasCoordinates(
      x,
      y,
      offsetWidth,
      offsetHeight,
      canvas.clientWidth,
      canvas.clientHeight,
    );

    if (startWebGLX === null) {
      return {
        centerX: endWebGLX,
        centerY: endWebGLY,
        bottomLeftX: endWebGLX,
        bottomLeftY: endWebGLY,
        width: 0,
        height: 0,
      };
    }

    const bottomLeftX = Math.min(startWebGLX, endWebGLX);
    const bottomLeftY = Math.min(startWebGLY, endWebGLY);
    const selectionWidth = Math.abs(endWebGLX - startWebGLX);
    const selectionHeight = Math.abs(endWebGLY - startWebGLY);

    return {
      bottomLeftX,
      bottomLeftY,
      width: selectionWidth,
      height: selectionHeight,
    };
  }

  function getCocosDetails() {
    const { x: endCocosX, y: endCocosY } = toCocosCoordinates(x, y, offsetWidth, offsetHeight);

    if (startCocosX === null) {
      return {
        centerX: endCocosX,
        centerY: endCocosY,
        startCocosX: endCocosX,
        startCocosY: endCocosY,
        width: 0,
        height: 0,
      };
    }

    const width = Math.abs(endCocosX - startCocosX);
    const height = Math.abs(endCocosY - startCocosY);
    const centerX = Math.round(Math.min(startCocosX, endCocosX) + width / 2);
    const centerY = Math.round(Math.min(startCocosY, endCocosY) + height / 2);

    return {
      centerX,
      centerY,
      startCocosX,
      startCocosY,
      width,
      height,
    };
  }

  return {
    webGL: getWebGLDetails(),
    cocos: getCocosDetails(),
  };
}
