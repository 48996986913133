export const EMAIL_REGEX = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

export const INPUT_TYPES = {
  EMAIL: 'email',
  TEXT: 'text',
  PASSWORD: 'password',
  DATE: 'date',
  NUMBER: 'number',
};

export const INPUT_COLORS = {
  PURPLE: 'purple',
};

export const INPUT_DEBOUNCE_MS = 350;
