import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as styles from './Modal.css';

const ModalHeader = ({ title, subtitle, image }) => {
  const modalHeaderStyles = cx(styles.modalHeader, {
    [styles.modalHeaderWithSubtitle]: Boolean(subtitle),
  });

  return (
    <div className={modalHeaderStyles}>
      {image && <div className={styles.modalImage}>{image}</div>}
      <h6>{title}</h6>
      {subtitle && <p>{subtitle}</p>}
    </div>
  );
};

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  image: PropTypes.element,
};
ModalHeader.defaultProps = {
  subtitle: '',
  image: null,
};

export default ModalHeader;
