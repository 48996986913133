import * as React from 'react';
import PropTypes from 'prop-types';

import * as utils from '../../utils';
import * as styles from './Image.css';

class Image extends React.PureComponent {
  static propTypes = {
    standard: PropTypes.string.isRequired,
    retina: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    startStyles: PropTypes.object,
    endStyles: PropTypes.object,
  };

  static defaultProps = {
    startStyles: null,
    endStyles: null,
  };

  constructor(props) {
    super(props);

    this.imageRef = React.createRef();
    this.updateImagePositioning = this.updateImagePositioning.bind(this);
    this.state = {};
  }

  componentDidMount() {
    const { startStyles, endStyles } = this.props;

    if (startStyles && endStyles) {
      window.addEventListener('scroll', this.updateImagePositioning);
      requestAnimationFrame(this.updateImagePositioning);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateImagePositioning);
  }

  updateImagePositioning() {
    const { startStyles, endStyles } = this.props;
    const scrollPercentDecimal = utils.getScrollPercentDecimal();
    Object.keys(startStyles).forEach((styleName) => {
      const currentStyle = utils.getStyleProgressFromTo(
        startStyles[styleName],
        endStyles[styleName],
        scrollPercentDecimal,
      );

      this.imageRef.current.style[styleName] = currentStyle;
    });
  }

  render() {
    const { standard, retina, alt } = this.props;
    const srcSet = `${standard}, ${retina} 2x`;

    return (
      <img className={styles.image} ref={this.imageRef} src={retina} srcSet={srcSet} alt={alt} />
    );
  }
}

export default Image;
