import * as React from 'react';
import PropTypes from 'prop-types';

import Modal from './Modal';
import { PurpleButton } from '../../../core/components/button/Button';
import { PurpleInput } from '../../../core/components/input/Input';
import { INPUT_TYPES } from '../../../core/components/input/constants';
import * as mailchimpService from '../../services/mailchimp/mailchimpService';
import ModalHeader from './ModalHeader';

import * as styles from './Modal.css';

class SignUpModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  state = {
    profile: {
      fullName: {
        value: '',
        isValid: false,
      },
      email: {
        value: '',
        isValid: false,
      },
    },
    isLoading: false,
    hasSubmitted: false,
  };

  modalRef = React.createRef();

  handleProfileChange(prop, value, isValid) {
    this.setState(({ profile }) => ({
      profile: {
        ...profile,
        [prop]: {
          value,
          isValid,
        },
      },
    }));
  }

  async handleSubmit(e) {
    e && e.preventDefault();

    const canSubmit = this.isValid();
    if (!canSubmit) {
      return;
    }

    this.setState({ isLoading: true });

    const { profile } = this.state;
    await mailchimpService.signUp(profile.fullName.value, profile.email.value);

    this.setState({ hasSubmitted: true });

    setTimeout(() => this.modalRef.current.handleClose(true), 1000);
  }

  isValid() {
    const { profile, isLoading } = this.state;
    const profileValues = Object.values(profile);
    const isValid = profileValues.every((field) => field.isValid);

    return isValid && !isLoading;
  }

  getSignUpButtonLabel() {
    const { hasSubmitted, isLoading } = this.state;

    if (hasSubmitted) {
      return 'Success! Thanks for signing up!';
    }

    if (isLoading) {
      return 'Loading…';
    }

    return 'Sign Up';
  }

  render() {
    const { isOpen, onClose } = this.props;
    const { profile } = this.state;
    const canSubmit = this.isValid();
    const signUpButtonLabel = this.getSignUpButtonLabel();

    return (
      <Modal ref={this.modalRef} isOpen={isOpen} onClose={onClose}>
        <ModalHeader
          title="Get Notified About Hellosaurus"
          subtitle="Drop your info below and we’ll notify you of new episodes of The Birthday Show as well as releases of future interactive shows and other Hellosaurus updates."
        />
        <form
          onSubmit={this.handleSubmit.bind(this)}
          ref={this.formRef}
          className={styles.signupModalForm}
        >
          <p>Sign up is for grownups :)</p>
          <PurpleInput
            ref={this.firstNameRef}
            placeholder="Full Name"
            value={profile.fullName.value}
            onChange={(value, isValid) => this.handleProfileChange('fullName', value, isValid)}
          />
          <PurpleInput
            placeholder="Email"
            value={profile.email.value}
            onChange={(value, isValid) => this.handleProfileChange('email', value, isValid)}
            type={INPUT_TYPES.EMAIL}
          />
          <PurpleButton
            label={signUpButtonLabel}
            isDisabled={!canSubmit}
            onClick={(e) => this.handleSubmit(e)}
          />
          {/* Allows submission of form through pressing enter */}
          <button aria-label="Submit" type="submit" hidden />
        </form>
        <p>
          Are you a creator interested in making your own interactive shows? Get in touch for more
          info on the Hellosaurus platform.
        </p>
      </Modal>
    );
  }
}

export default SignUpModal;
