import * as React from 'react';
import PropTypes from 'prop-types';

import * as styles from './Alert.css';

class Alert extends React.PureComponent {
  static propTypes = {
    icon: PropTypes.element,
    label: PropTypes.string.isRequired,
  };

  static defaultProps = {
    icon: null,
  };

  render() {
    const { label, icon } = this.props;

    return (
      <div className={styles.alertContainer}>
        <div className={styles.alert}>
          <p>{label}</p>
          {icon && <span>{icon}</span>}
        </div>
      </div>
    );
  }
}

export default Alert;
