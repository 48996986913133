class Configuration {
  REQUEST_SHOUTOUT_FORM_URL =
    'https://docs.google.com/forms/d/e/1FAIpQLSeHPyZpx43ZDS0T_3wmWjI7ZhHo8ntZhggOKq96fBtjoYI6Wg/viewform?usp=sf_link';

  SHARE_A_PRESENT_FORM_URL = 'https://forms.gle/gMYZNBxuaDXC9bEk8';

  PRESS_KIT_URL = 'https://drive.google.com/drive/u/2/folders/1O_Sr0BXytzXXJZy9wJjF4Zn3OIM7AQ2r';

  // Show the full countdown when below this threshold of the next video
  FULL_COUNTDOWN_DURATION_MS = 1000 * 60 * 15;

  // If someone joins a tiny bit late, or their internet is bad, start their video at the beginning
  JOIN_GRACE_SECS = 10;

  PRODUCTION_URL = window.location.host || 'hellosaurus.com';

  JOBS_URL =
    'https://www.notion.so/hellosaurus/Join-us-at-Hellosaurus-50bb1d78b109465db763c67812a915c1';

  THE_BIRTHDAY_SHOW_ID = 'ckbtpbp2k000195n1hj3q09xg';
}

export default Configuration;
