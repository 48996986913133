import React from 'react';
import { Link } from 'react-router-dom';

import standardDinosaurRightSideWaving from '../../assets/dinosaur-right-side-waving.png';
import retinaDinosaurRightSideWaving from '../../assets/dinosaur-right-side-waving@2x.png';

import standardLogo from '../../assets/logo.png';
import retinaLogo from '../../assets/logo@2x.png';

import * as commonStyles from '../../index.css';
import * as styles from './NotFoundPage.css';

import { YellowButton } from '../components/button/Button';
import PageLayout from '../../thebirthdayshow/components/page-layout/PageLayout';

class NotFoundPage extends React.PureComponent {
  render() {
    return (
      <div className={styles.notFoundPage}>
        <PageLayout>
          <div className={commonStyles.row}>
            <div className={styles.notFoundPageBody}>
              <div className={styles.notFoundPageBodyLeft} />
              <div className={styles.notFoundPageBodyMiddle}>
                <div className={styles.logo}>
                  <Link to="/">
                    <img
                      src={retinaLogo}
                      srcSet={`${standardLogo}, ${retinaLogo} 2x`}
                      alt="The Birthday Show"
                    />
                  </Link>
                </div>
                <h1>404 Not Found!</h1>
                <p>Sorry! We couldn&apos;t find the page you were looking for.</p>
                <YellowButton label="Go to the homepage" href="/" isLarge />
              </div>
              <div className={styles.notFoundPageBodyRight}>
                <img
                  src={retinaDinosaurRightSideWaving}
                  srcSet={`${standardDinosaurRightSideWaving}, ${retinaDinosaurRightSideWaving} 2x`}
                  alt="Dinosaur Waving"
                />
              </div>
            </div>
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default NotFoundPage;
